import React from "react";
import { Link } from "gatsby";

import Layout from "@rocketseat/gatsby-theme-docs/src/components/Layout";
import SEO from "@rocketseat/gatsby-theme-docs/src/components/SEO";

export default function NotFound() {
  return (
    <Layout title="Страница не найдена!">
      <SEO title="404: Not found" />
      <p>Этой страницы не существует... Печалька :(</p>
      <p>
        Если ты заблудился, <Link to="/">начни сначала</Link>.
      </p>
    </Layout>
  );
}
